




import { Component, Inject, Vue, Watch } from 'vue-property-decorator'
import {
  setLocale,
  removeToken,
  removeSchoolInfo,
  getSchoolInfo,
  setSchoolInfo,
  getLocale,
  setUserAuth,
} from '@/utils/utils'
@Component
export default class TeacherLogin extends Vue {
  @Watch('$route', { immediate: true })
  private onRouteChanged(to: any, from: any) {
    // removeToken()
    // sessionStorage.setItem('token', to.params.teacherToken)
    const isPc = !navigator.userAgent.toLowerCase().includes('mobile')
    window.location.href = window.location.origin
  }
}
